import clsx from 'clsx';
import React from 'react';
import {
  getBgColorClass,
  getTextColorClass,
  getBorderColorClass,
  ThemeColor,
} from 'src/DesignSystem';

interface Props {
  color: ThemeColor;
}

export default function Box(props: React.PropsWithChildren<Props>): JSX.Element {
  return (
    <div
      className={clsx(
        getBgColorClass(props.color),
        getTextColorClass(props.color),
        getBorderColorClass(props.color),
        'border-2 rounded px-4 py-2'
      )}
    >
      {props.children}
    </div>
  );
}
