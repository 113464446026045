
import React from 'react';
import { AbilityScoreNumbers } from 'src/components/dnd/dnd-types';

interface Props {
  scores: AbilityScoreNumbers;
  bgColor: string;
}

function calcDisplayMod(number: number): string {
  if (number <= 0) {
    return number.toString();
  } else {
    const mod: number = Math.floor((number - 10) / 2);
    const sign: string = mod >= 0 ? '+' : '';
    return `${number.toString()}  (${sign}${mod})`;
  }
}

function makeScoreElement(name: string, number: number): JSX.Element {
  return (
    <div className="flex flex-col items-center">
      <div className="uppercase font-medium tracking-wide">{name}</div>
      <div>{calcDisplayMod(number)}</div>
    </div>
  );
}

export default function AbilityScores(props: Props): JSX.Element {
  return (
    <div className={'flex flex-row justify-around rounded py-2 my-2 ' + props.bgColor}>
      {makeScoreElement('STR', props.scores.str)}
      {makeScoreElement('DEX', props.scores.dex)}
      {makeScoreElement('CON', props.scores.con)}
      {makeScoreElement('INT', props.scores.int)}
      {makeScoreElement('WIS', props.scores.wis)}
      {makeScoreElement('CHA', props.scores.cha)}
    </div>
  );
}
