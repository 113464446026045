import React, { PropsWithChildren } from 'react';
import Box from 'src/components/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSword } from '@fortawesome/pro-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';

interface Props {
  name: string;
  subtitle: string;
}

export default function MagicItem(props: PropsWithChildren<Props>): JSX.Element {
  console.log(props.children);

  return (
    <Box color="pink">
      <div className="flex justify-between items-baseline">
        <div className="uppercase tracking-wide font-medium text-lg text-pink-700 mb-2">
          {props.name}
        </div>
        <span data-tip="magic-item" className="fa-layers fa-fw text-pink-500">
          <FontAwesomeIcon icon={faSword} />
          <FontAwesomeIcon icon={faSparkles} transform="shrink-4 up-6 left-2" opacity={.5}/>
        </span>
        <ReactTooltip place="top" type="dark" effect="solid">
          Magic Item
        </ReactTooltip>
      </div>
      <div className="italic mb-2">{props.subtitle}</div>
      {props.children}
    </Box>
  );
}
