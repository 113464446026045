import React from 'react';
import * as DesignSystem from 'src/DesignSystem';
import ReactMarkdown from 'react-markdown';

export function Property(
  props: React.PropsWithChildren<{ title: string }>
): JSX.Element {
  return (
    <div>
      <span className="font-medium">{props.title}</span> {props.children}
    </div>
  );
}

export function OptionalPropertyFromList(props: {
  title: string;
  content?: string[];
}): JSX.Element | null {
  if (props.content) {
    return <Property title={props.title}>{props.content?.join(', ')}</Property>;
  } else {
    return null;
  }
}

export function Trait(
  props: React.PropsWithChildren<{ title: string }>
): JSX.Element {
  return (
    <div className="indent-hanging">
      <span className="font-medium">{props.title}.</span>{' '}
      <ReactMarkdown
        renderers={{
          paragraph: DesignSystem.StatblockTraitParagraph,
          strong: DesignSystem.Strong,
        }}
      >
        {props.children}
      </ReactMarkdown>
    </div>
  );
}

export function Section(
  props: React.PropsWithChildren<{ lineColor: DesignSystem.ThemeColor }>
): JSX.Element {
  return (
    <div
      className={`text-lg font-bold my-2 first:mt-0 border-b ${DesignSystem.getLineColorClass(
        props.lineColor
      )}`}
    >
      {props.children}
    </div>
  );
}

export function Line(props: { color: string }): JSX.Element {
  return <div className={'my-2 w-full h-2px rounded ' + props.color} />;
}
