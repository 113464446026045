import React from 'react';
import Box from 'src/components/Box';
import { Paragraph } from 'src/DesignSystem';
import ReactMarkdown from 'react-markdown';
import { Line } from 'src/components/dnd/statblock/StatblockUtilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { faBookSpells } from '@fortawesome/pro-duotone-svg-icons';

interface Props {
  name: string;
  level: string;
  school: string;
  components: string;
  componentDescription?: string;
  range: string;
  duration: string;
  castingTime: string;
  spellText: string;
}

export default function Spell(props: Props): JSX.Element {
  return (
    <Box color="indigo">
      <div className="flex justify-between items-baseline">
        <div className="uppercase tracking-wide font-medium text-lg text-indigo-700 mb-2">
          {props.name}
        </div>
        <FontAwesomeIcon data-tip="spell" icon={faBookSpells} className="text-indigo-500" />
        <ReactTooltip place="top" type="dark" effect="solid">Spell</ReactTooltip>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 text-indigo-900">
        <div className="px-2 py-1 bg-indigo-200 rounded">
          <div className="font-medium">Level</div>
          <div>{props.level}</div>
        </div>
        <div className="px-2 py-1 bg-indigo-200 rounded">
          <div className="font-medium">School</div>
          <div>{props.school}</div>
        </div>
        <div className="px-2 py-1 bg-indigo-200 rounded">
          <div className="font-medium">Components</div>
          <div>{props.components}</div>
        </div>
        <div className="px-2 py-1 bg-indigo-200 rounded">
          <div className="font-medium">Casting Time</div>
          <div>{props.castingTime}</div>
        </div>
        <div className="px-2 py-1 bg-indigo-200 rounded">
          <div className="font-medium">Range</div>
          <div>{props.range}</div>
        </div>
        <div className="px-2 py-1 bg-indigo-200 rounded">
          <div className="font-medium">Duration</div>
          <div>{props.duration}</div>
        </div>
      </div>
      <ReactMarkdown
        renderers={{ paragraph: Paragraph }}
        className="mt-3 text-indigo-900"
      >
        {props.spellText}
      </ReactMarkdown>
      {props.componentDescription && (
        <>
          <Line color="bg-indigo-200" />
          <div className="text-indigo-700 italic text-sm">
            * {props.componentDescription}
          </div>
        </>
      )}
    </Box>
  );
}
