import React from 'react';
import AbilityScores from 'src/components/dnd/statblock/AbilityScores';
import {
  Line,
  Property,
  Section,
  Trait,
  OptionalPropertyFromList,
} from 'src/components/dnd/statblock/StatblockUtilities';
import { ShipStats } from 'src/components/dnd/dnd-types';
import {
  getBgColorClass,
  getBorderColorClass,
  getLineColorClass,
  getTagTextColorClass,
  getTextColorClass,
} from 'src/DesignSystem';
import clsx from 'clsx';

interface Props {
  ship: ShipStats;
}

export default function ShipStatblock(props: Props): JSX.Element {
  const ship = props.ship;
  return (
    <div
      className={clsx(
        'md:columns-2',
        'columns-balance',
        'my-2',
        getBgColorClass('blue'),
        'border-2',
        'rounded',
        getBorderColorClass('blue'),
        'p-4',
        'mx-auto',
        getTextColorClass('blue')
      )}
    >
      <div className={clsx('text-xl', 'font-bold', getTagTextColorClass('blue'))}>{ship.name}</div>
      <div className="italic">{ship.rate}</div>
      <Line color="bg-blue-300" />
      <Property title="Actions">{ship.actions}</Property>
      <Property title="Creature Capacity">{ship.creatureCapacity}</Property>
      <Property title="Cargo Capacity">{ship.cargoCapacity}</Property>
      <Property title="Travel Pace">{ship.travelPace}</Property>
      <AbilityScores bgColor="bg-blue-200" scores={ship.abilityScores} />
      <OptionalPropertyFromList
        title="Damage Vulnerabilities"
        content={ship.globalVri?.vulnerabilities}
      />
      <OptionalPropertyFromList title="Damage Resistances" content={ship.globalVri?.resistances} />
      <OptionalPropertyFromList title="Damage Immunities" content={ship.globalVri?.immunities} />
      <OptionalPropertyFromList title="Condition Immunities" content={ship.conditionImmunities} />
      <OptionalPropertyFromList title="Weapon Slots" content={ship.weaponSlots} />
      <Line color={getLineColorClass('blue')} />
      {ship.components.map(component => (
        <React.Fragment key={component.name}>
          <Section lineColor="blue">{component.name}</Section>
          <Property title="Armor Class">{component.ac}</Property>
          <Property title="Hit Points">{component.hp}</Property>
          <OptionalPropertyFromList
            title="Damage Vulnerabilities"
            content={component.vri?.vulnerabilities}
          />
          <OptionalPropertyFromList
            title="Damage Resistances"
            content={component.vri?.resistances}
          />
          <OptionalPropertyFromList title="Damage Immunities" content={component.vri?.immunities} />
          {component.crew && <Property title="Crew">{component.crew}</Property>}
          {component.actions?.map(action => (
            <Trait key={action.title} title={action.title}>
              {action.content}
            </Trait>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
}
