import React from 'react';
import {
  ThemeColor,
  getTagColorClass,
  getTagTextColorClass,
  getTagIconColorClass,
} from 'src/DesignSystem';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

interface Props {
  color?: ThemeColor;
  icon?: IconDefinition;
}

export default function Tag(
  props: React.PropsWithChildren<Props>
): JSX.Element {
  const color = props.color || 'blue';

  return (
    <div
      className={clsx(
        'inline-block',
        getTagColorClass(color),
        'font-medium',
        getTagTextColorClass(color),
        'rounded',
        'text-sm',
        'py-1',
        'px-2',
        'my-1',
        'mr-2'
      )}
    >
      {props.icon && (
        <FontAwesomeIcon
          icon={props.icon}
          className={clsx('mr-2', getTagIconColorClass(color))}
        />
      )}
      {props.children}
    </div>
  );
}
