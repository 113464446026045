import React, { PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDragon } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

export type ThemeColor =
  | 'grey'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'lime'
  | 'green'
  | 'teal'
  | 'blue'
  | 'indigo'
  | 'purple'
  | 'pink';

export const prettyColors: ThemeColor[] = [
  'red',
  'orange',
  'yellow',
  'lime',
  'green',
  'teal',
  'blue',
  'indigo',
  'purple',
  'pink',
];

type DesignProps = PropsWithChildren<{ className?: string }>;

export const H1 = (props: DesignProps): JSX.Element => (
  <h1 {...props} className={clsx('font-bold text-4xl', props.className)} />
);

export const H2 = (props: DesignProps): JSX.Element => (
  <h2 {...props} className={clsx('font-bold mt-4 text-2xl', props.className)} />
);

export const ElegantH2 = (props: DesignProps): JSX.Element => (
  <div>
    <span className="relative mb-6">
      <div className="absolute bg-purple-200 h-5 inset-x-0 mx-2 -mb-1 rounded bottom-0 z-0" />
      <H2 {...props} className={clsx('relative inline z-10 text-3xl mb-4', props.className)} />
    </span>
  </div>
);

export const H3 = (props: DesignProps): JSX.Element => (
  <h3
    {...props}
    className={clsx('font-medium mt-4 text-xl border-b mb-2 border-blue-300', props.className)}
  />
);

export const H4 = (props: DesignProps): JSX.Element => (
  <h4
    {...props}
    className={clsx('uppercase tracking-wide font-medium mt-4 pb-2 first:mt-0', props.className)}
  />
);

export const H5 = (props: DesignProps): JSX.Element => <h5 {...props} className="text-xl" />;

export const H6 = (props: DesignProps): JSX.Element => <h6 {...props} className="text-xl" />;

export const Strong = (props: DesignProps): JSX.Element => (
  <strong {...props} className="font-medium" />
);

export const Table = (props: DesignProps): JSX.Element => (
  <table {...props} className="table-auto w-full mt-4 mb-8" />
);

export const Th = (props: DesignProps): JSX.Element => (
  <th {...props} className="text-left px-4 font-medium" />
);

export const Td = (props: DesignProps): JSX.Element => (
  <td {...props} className="border px-4 py-2" />
);

export const Blockquote = (props: DesignProps): JSX.Element => (
  <blockquote
    {...props}
    className="bg-blue-50 text-blue-900 border-2 border-blue-200 px-4 py-2 mb-4 rounded"
  />
);

export const Paragraph = (props: DesignProps): JSX.Element => (
  <p {...props} className={clsx('mb-3 leading-relaxed last:mb-0', props.className)} />
);

export const A = (
  props: DesignProps & { href?: string; rel?: string; target?: string }
): JSX.Element => (
  <a
    {...props}
    rel="noopener"
    className={clsx('border-b-2 border-blue-300 hover:border-blue-500 ', props.className)}
  />
);

export const Li = (props: DesignProps): JSX.Element => (
  <li {...props} className={clsx('flex items-baseline my-2 ', props.className)}>
    <FontAwesomeIcon icon={faDragon} className="mr-3 text-purple-500" />
    <div>{props.children}</div>
  </li>
);

export const Ul = (props: DesignProps): JSX.Element => (
  <ul {...props} className={clsx('mt-4 ', props.className)} />
);

export const StatblockTraitParagraph = (props: DesignProps): JSX.Element => (
  <p {...props} className="inline" />
);

// This is needed because if the full class names don't show up in our code, they get purged.
export function getBgColorClass(color: ThemeColor): string {
  switch (color) {
    case 'red':
      return 'bg-red-50';
    case 'orange':
      return 'bg-orange-50';
    case 'yellow':
      return 'bg-yellow-50';
    case 'lime':
      return 'bg-lime-50';
    case 'green':
      return 'bg-green-50';
    case 'teal':
      return 'bg-teal-50';
    case 'blue':
      return 'bg-blue-50';
    case 'indigo':
      return 'bg-indigo-50';
    case 'purple':
      return 'bg-purple-50';
    case 'pink':
      return 'bg-pink-50';
    case 'grey':
    default:
      return 'bg-grey-50';
  }
}

// This is needed because if the full class names don't show up in our code, they get purged.
export function getTagColorClass(color: ThemeColor): string {
  switch (color) {
    case 'red':
      return 'bg-red-200';
    case 'orange':
      return 'bg-orange-200';
    case 'yellow':
      return 'bg-yellow-200';
    case 'lime':
      return 'bg-lime-200';
    case 'green':
      return 'bg-green-200';
    case 'teal':
      return 'bg-teal-200';
    case 'blue':
      return 'bg-blue-200';
    case 'indigo':
      return 'bg-indigo-200';
    case 'purple':
      return 'bg-purple-200';
    case 'pink':
      return 'bg-pink-200';
    case 'grey':
    default:
      return 'bg-grey-200';
  }
}

// This is needed because if the full class names don't show up in our code, they get purged.
export function getTextColorClass(color: ThemeColor): string {
  switch (color) {
    case 'red':
      return 'text-red-900';
    case 'orange':
      return 'text-orange-900';
    case 'yellow':
      return 'text-yellow-900';
    case 'lime':
      return 'text-lime-900';
    case 'green':
      return 'text-green-900';
    case 'teal':
      return 'text-teal-900';
    case 'blue':
      return 'text-blue-900';
    case 'indigo':
      return 'text-indigo-900';
    case 'purple':
      return 'text-purple-900';
    case 'pink':
      return 'text-pink-900';
    case 'grey':
    default:
      return 'text-grey-900';
  }
}

// This is needed because if the full class names don't show up in our code, they get purged.
export function getTagIconColorClass(color: ThemeColor): string {
  switch (color) {
    case 'red':
      return 'text-red-500';
    case 'orange':
      return 'text-orange-500';
    case 'yellow':
      return 'text-yellow-500';
    case 'lime':
      return 'text-lime-500';
    case 'green':
      return 'text-green-500';
    case 'teal':
      return 'text-teal-500';
    case 'blue':
      return 'text-blue-500';
    case 'indigo':
      return 'text-indigo-500';
    case 'purple':
      return 'text-purple-500';
    case 'pink':
      return 'text-pink-500';
    case 'grey':
    default:
      return 'text-grey-500';
  }
}

// This is needed because if the full class names don't show up in our code, they get purged.
export function getTagTextColorClass(color: ThemeColor): string {
  switch (color) {
    case 'red':
      return 'text-red-800';
    case 'orange':
      return 'text-orange-800';
    case 'yellow':
      return 'text-yellow-800';
    case 'lime':
      return 'text-lime-800';
    case 'green':
      return 'text-green-800';
    case 'teal':
      return 'text-teal-800';
    case 'blue':
      return 'text-blue-800';
    case 'indigo':
      return 'text-indigo-800';
    case 'purple':
      return 'text-purple-800';
    case 'pink':
      return 'text-pink-800';
    case 'grey':
    default:
      return 'text-grey-800';
  }
}

// This is needed because if the full class names don't show up in our code, they get purged.
export function getBorderColorClass(color: ThemeColor): string {
  switch (color) {
    case 'red':
      return 'border-red-200';
    case 'orange':
      return 'border-orange-200';
    case 'yellow':
      return 'border-yellow-200';
    case 'lime':
      return 'border-lime-200';
    case 'green':
      return 'border-green-200';
    case 'teal':
      return 'border-teal-200';
    case 'blue':
      return 'border-blue-200';
    case 'indigo':
      return 'border-indigo-200';
    case 'purple':
      return 'border-purple-200';
    case 'pink':
      return 'border-pink-200';
    case 'grey':
    default:
      return 'border-grey-200';
  }
}

// This is needed because if the full class names don't show up in our code, they get purged.
export function getLineColorClass(color: ThemeColor): string {
  switch (color) {
    case 'red':
      return 'border-red-300';
    case 'orange':
      return 'border-orange-300';
    case 'yellow':
      return 'border-yellow-300';
    case 'lime':
      return 'border-lime-300';
    case 'green':
      return 'border-green-300';
    case 'teal':
      return 'border-teal-300';
    case 'blue':
      return 'border-blue-300';
    case 'indigo':
      return 'border-indigo-300';
    case 'purple':
      return 'border-purple-300';
    case 'pink':
      return 'border-pink-300';
    case 'grey':
    default:
      return 'border-grey-300';
  }
}

export function getFaintIconColorClass(color: ThemeColor): string {
  switch (color) {
    case 'red':
      return 'text-red-400';
    case 'orange':
      return 'text-orange-400';
    case 'yellow':
      return 'text-yellow-400';
    case 'lime':
      return 'text-lime-400';
    case 'green':
      return 'text-green-400';
    case 'teal':
      return 'text-teal-400';
    case 'blue':
      return 'text-blue-400';
    case 'indigo':
      return 'text-indigo-400';
    case 'purple':
      return 'text-purple-400';
    case 'pink':
      return 'text-pink-400';
    case 'grey':
    default:
      return 'text-grey-400';
  }
}
