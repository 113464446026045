import React from 'react';
import { faCoins } from '@fortawesome/pro-solid-svg-icons';
import Tag from 'src/components/Tag';

interface Props {
  price: string;
}
export default function CostBox(props: Props): JSX.Element {
  return (
    <Tag color="yellow" icon={faCoins}>
      {props.price}
    </Tag>
  );
}
