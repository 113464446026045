import React, { PropsWithChildren } from 'react';
import { ElegantH2 } from 'src/DesignSystem';

interface Props {
  title: string;
  location?: string;
  level: 2 | 3;
  sidebar?: JSX.Element[] | JSX.Element;
}

export default function AdventureSection(props: PropsWithChildren<Props>): JSX.Element {
  return (
    <>
      <div className={`mb-4 w-full bg-gray-300 rounded ${props.level === 2 ? 'h-4px' : 'h-2px'}`} />
      <div className="mb-4 flex items-center">
        {props.location && (
          <div className="border-purple-700 text-purple-700 border-2 rounded-md uppercase tracking-wider font-bold px-2 mr-4">
            {props.location}
          </div>
        )}
        {props.level === 2 ? (
          <ElegantH2>{props.title}</ElegantH2>
        ) : (
          <h3 className="text-2xl font-semibold">{props.title}</h3>
        )}
      </div>
      <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
        <div className="col-span-1">{props.children}</div>
        {props.sidebar && <div className="col-span-1">{props.sidebar}</div>}
      </div>
    </>
  );
}
