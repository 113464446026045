// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-layouts-adventure-layout-tsx": () => import("./../src/components/layouts/AdventureLayout.tsx" /* webpackChunkName: "component---src-components-layouts-adventure-layout-tsx" */),
  "component---src-components-layouts-article-layout-tsx": () => import("./../src/components/layouts/ArticleLayout.tsx" /* webpackChunkName: "component---src-components-layouts-article-layout-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-adventures-index-tsx": () => import("./../src/pages/adventures/index.tsx" /* webpackChunkName: "component---src-pages-adventures-index-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-games-index-tsx": () => import("./../src/pages/games/index.tsx" /* webpackChunkName: "component---src-pages-games-index-tsx" */),
  "component---src-pages-games-little-devils-tsx": () => import("./../src/pages/games/little-devils.tsx" /* webpackChunkName: "component---src-pages-games-little-devils-tsx" */),
  "component---src-pages-games-neon-sun-tsx": () => import("./../src/pages/games/neon-sun.tsx" /* webpackChunkName: "component---src-pages-games-neon-sun-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tools-d-12-test-tsx": () => import("./../src/pages/tools/d12-test.tsx" /* webpackChunkName: "component---src-pages-tools-d-12-test-tsx" */),
  "component---src-pages-tools-index-tsx": () => import("./../src/pages/tools/index.tsx" /* webpackChunkName: "component---src-pages-tools-index-tsx" */),
  "component---src-pages-tools-names-tsx": () => import("./../src/pages/tools/names.tsx" /* webpackChunkName: "component---src-pages-tools-names-tsx" */),
  "component---src-pages-tools-treasure-tsx": () => import("./../src/pages/tools/treasure.tsx" /* webpackChunkName: "component---src-pages-tools-treasure-tsx" */)
}

